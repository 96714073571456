import React from "react"
import { PageWrapper } from "~components/Core"
import { Container, Tab, Row, Col } from "react-bootstrap"
import SearchSection from "./SearchSection"
import FooterSection from "../Footer"
import Faq from "./FaqSection/style"

export default function FaqItem({ pageContext }) {
  const { question, answer } = pageContext
  const header = {
    headerClasses: "position-relative",
  }
  return (
    <PageWrapper innerPage={true} headerConfig={header}>
      <SearchSection />
      <Faq>
        <Container>
          <Faq.Body>
            <Faq.TabWrapper
              as={Tab.Container}
              id="left-tabs-example"
              defaultActiveKey="first"
            >
              <Row className="justify-content-center">
                <Col className="col-xl-10">
                  <Faq.Accordion>
                    <div className="accordion">
                      <div className="accordion-item">
                        <h1 className="accordion-title">{question}</h1>
                        <div
                          className="accordion-answer"
                          dangerouslySetInnerHTML={{ __html: answer }}
                        />
                      </div>
                    </div>
                  </Faq.Accordion>
                </Col>
              </Row>
            </Faq.TabWrapper>
          </Faq.Body>
        </Container>
      </Faq>
      <FooterSection />
    </PageWrapper>
  )
}
